<template>
  <div class="product">
    <div
      class="product-image-wrapper"
      :class="{
        'light-theme': theme === 'light',
        'dark-theme': theme === 'dark',
      }"
      :href="item.handle"
      @click="openShop"
    >
      <img
        class="product-image"
        :src="item.image"
        alt=""
        @error="fallbackThumb"
      />
    </div>
    <div class="product-title" :href="item.handle" @click="openShop">
      {{ item.title | truncate(45) }}
    </div>
    <div class="product-details">
      <div class="product-price">
        <span class="current-price">{{ item.price | currencyLegacy }}</span>
        <span
          class="old-price"
          v-if="item.compare_at_price && item.compare_at_price !== '0'"
        >
          {{ item.compare_at_price | currencyLegacy }}
        </span>
      </div>
      <div class="product-cta">
        <mem-button
          class="action-button"
          btn-type="mem-primary"
          @click="openShop"
        >
          {{ $t("shop_now") }}
        </mem-button>
      </div>
    </div>
  </div>
</template>

<script>
// import localizeResolver from "@/common/mixins/localizeResolver";
import ProductPlaceholder from "@/assets/product_placeholder.png";

export default {
  name: "ProductItem",
  // mixins: [localizeResolver],
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    theme: String,
  },
  methods: {
    fallbackThumb(e) {
      if (e.target && e.target.src) {
        e.target.src = ProductPlaceholder;
      }
    },
    openShop() {
      this.$emit("item-click", this.item);
      window.open(this.item.handle, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  display: flex;
  flex-direction: column;
  max-width: 289px;
  background: #121212;
  padding-bottom: 26px;

  .product-image-wrapper {
    display: block;
    position: relative;
    flex: 1 0;
    overflow: hidden;
    box-sizing: border-box;
    // background: #ffffff;

    .product-image {
      display: block;
      width: 100%;
      user-select: none;
      height: 227px;
      object-fit: contain;
      border: 0;
      // mix-blend-mode: multiply;
      box-sizing: border-box;
    }
  }
  .light-theme {
    background: #ffffff;
  }
  .dark-theme {
    background: #000000;
  }

  .product-title {
    padding: 19px 0;
    margin: 0 24px;
    max-height: 70px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.3;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;

    &:active,
    &:visited {
      color: #ffffff;
      text-decoration: none;
    }
  }

  .product-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;
    margin-right: 19px;

    .product-price {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .current-price {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 1.2;
        color: #ffffff;

        & + .old-price {
          margin-left: 8px;
        }
      }
      .old-price {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        color: #aaaaaa;
        text-decoration: line-through;
      }
    }

    .product-cta {
      display: flex;
      justify-content: flex-end;

      .action-button {
        width: 119px;
        height: 36px;
        font-size: 12px;
        line-height: 15px;
        padding: 0;
      }
    }
  }
}
</style>
