<template>
  <div class="product-carousel">
    <div v-for="(item, key) in items" :key="key" class="carousel-item">
      <product-item
        :item="item"
        :key="key"
        :theme="theme"
        @item-click="itemClick"
      />
    </div>
  </div>
</template>

<script>
import ProductItem from "@/components/shop/ProductItem";

export default {
  name: "ProductCarousel",
  components: {
    ProductItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: "light",
    },
  },
  methods: {
    itemClick(item) {
      this.$emit("on-item-click", item);
    },
  },
};
</script>

<style lang="scss" scoped>
$scrollbarBG: rgba(255, 255, 255, 0.2);
$thumbBG: #fefefe;

.product-carousel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 15px;
  /* Works on Mozilla */
  scrollbar-width: thin;
  scrollbar-color: $thumbBG $scrollbarBG;

  .carousel-item {
    width: auto;
    float: left;
    user-select: none;
    flex-shrink: 0;

    & + .carousel-item {
      margin-left: 20px;
    }
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbarBG; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumbBG; /* color of the scroll thumb */
    border-radius: 0; /* roundness of the scroll thumb */
    border: 0; /* creates padding around scroll thumb */
  }

  &:hover {
    padding-bottom: 12px;
    @-moz-document url-prefix() {
      padding-bottom: 15px !important;
    }
    &::-webkit-scrollbar {
      height: 6px;
      margin-bottom: 0;
    }
  }
}
</style>
